import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe((event) => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Tableau de bord",
      icon: "home",
      type: "link",
      badgeType: "light-primary",
      active: false,
      path: "/admin-dashboard",
    },
    {
      headTitle1: "Candidats",
      headTitle2: "Gestion des candidats",
    },
    {
      title: "Nouveau Compte",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des nouveaux candidats",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "candidate/account/list-candidates",
        },


      ],
    },
    {
      title: "Formation Candidat",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des candidats en formation",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "candidate/account/list-formations",
        },
      ],
    },
    {
      title: "Employé",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des candidats en employé",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "candidate/account/list-candidates",
        },
      ],
    },


    {
      headTitle1: "Recruteurs",
      headTitle2: "Gestion des recruteurs",
    },

    {
      title: "Recruteurs et actions",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des recruteurs",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "recruiter/list-recruiters",
        },


      ],
    },

    {
      headTitle1: "Entretiens",
      headTitle2: "Gestion des entretiens",
    },
    {
      title: "Entretiens et programmation",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Calendrier",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "interview/calendar",
        },


      ],
    },
    {
      headTitle1: "Gestion des formations",
      headTitle2: "Gestion des formations",
    },
    {
      title: "Formations",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des formations",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "training/list-trainings",
        },


      ],
    },
    {
      headTitle1: "Paiements",
      headTitle2: "Gestion des paiements",
    },
    {
      title: "Paiement des formations",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des paiements formations",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "paiement/liste-paiement-training",
        },


      ],
    },

    {
      title: "Abonnement recruteurs",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des abonnements",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "paiement/liste-paiement-recruiter",
        },


      ],
    },
    {
      title: "Paiement de salaire",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des paiements de salaire",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "paiement/liste-paiement-salary",
        },


      ],
    },

    {
      title: "Commissions",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Liste des commissions sur formation",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "paiement/liste-profit-formation",
        },

        {
          title: "Liste des commissions sur salaire",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "paiement/liste-profit-salary",
        },




      ],
    },
    {
      headTitle1: "Blog",
      headTitle2: "Gestion des articles de blog",
    },
    {
      title: "Gestion des articles",
      icon: "blog",
      type: "sub",
      active: false,
      children: [
        {
          title: "Créer un article",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/article/create-article",
        },
        {
          title: "Liste des articles",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/article/list-articles",
        },


      ],
    },
    {
      title: "Gestion des catégories ",
      icon: "task",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des catégories",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/category/list-categories",
        },


      ],
    },
    {
      title: "Gestion des tags",
      icon: "form",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des tags",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/tag/list-tags",
        },


      ],
    },
    {
      title: "Gestion des status",
      icon: "form",
      type: "sub",
      active: false,
      children: [

        {
          title: "Liste des status",
          icon: "stroke-icon",
          type: "link",
          active: false,
          path: "blog/status/list-status",
        },


      ],
    },
    {
      headTitle1: "Paramètres",
      headTitle2: "Gestion des paramètres",
    },

    {
      title: "Domaine d'activités",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-activities", title: "Liste des domaine d'activités", type: "link" }
      ],
    },
    {
      title: "Pays",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-countries", title: "Liste des pays", type: "link" }
      ],
    },
    {
      title: "Administrateurs",
      type: "sub",
      icon: "user",
      badgeType: "light-primary",
      active: false,
      children: [

        // { path: "/parameter/admin", title: "Ajouter un administrateur", type: "link" },
        { path: "/parameter/list-admins", title: "Liste des administrateurs", type: "link" },


      ],
    },
    {
      title: "Rôles",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-roles", title: "Liste des rôles", type: "link" }
      ],
    },
    {
      title: "Menu",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-menus", title: "Liste des menus", type: "link" }
      ],
    },
    {
      title: "Permission",
      type: "sub",
      icon: "social",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/parameter/list-permissions", title: "Liste des permissions", type: "link" }
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}

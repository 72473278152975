import { Routes } from "@angular/router";

export const content: Routes = [
    {
        path:'',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path:'admin-dashboard',
        loadChildren: () => import("../../admin/pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
    },
    {
        path: "candidate",
        loadChildren: () => import("../../admin/pages/candidate/candidate.module").then((m) => m.CandidateModule),
    },
    {
        path: "training",
        loadChildren: () => import("../../admin/pages/formation/formation.module").then((m) => m.FormationModule),
    },
    {
        path: "recruiter",
        loadChildren: () => import("../../admin/pages/recruiter/recruiter.module").then((m) => m.RecruiterModule),
    },
    {
        path: "interview",
        loadChildren: () => import("../../admin/pages/interview/interview.module").then((m) => m.InterviewModule),
    },
    {
        path: "salaried",
        loadChildren: () => import("../../admin/pages/salaried/salaried.module").then((m) => m.SalariedModule),
    },
    {
        path: "project",
        loadChildren: () => import("../../admin/pages/project/project.module").then((m) => m.ProjectModule),
    },
    
    {
        path: "blog",
        loadChildren: () => import("../../admin/pages/blog/blog.module").then((m) => m.BlogModule),
    },
    {
        path: "paiement",
        loadChildren: () => import("../../admin/pages/payment/payment.module").then((m) => m.PaymentModule),
    },
    {
        path: "parameter",
        loadChildren: () => import("../../admin/pages/parameter/parameter.module").then((m) => m.ParameterModule),
    },
    {
        path: "**",
        loadChildren: () => import("../../admin/pages/error/error.module").then((m) => m.ErrorModule),
    },
];

import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "src/app/shared/models/user";
import { AuthService } from "src/app/shared/services/auth.service";
import { LocalStorageService } from "src/app/shared/services/base/localStorage.service";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {
  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";
  user:any;


  constructor(public router: Router,private authService: AuthService,private localStorageService:LocalStorageService) {
    this.localStorageService.getCurrentUser().subscribe(result => { this.user = result; })

    if (JSON.parse(localStorage.getItem("user"))) {
    } else {
    }
  }

  ngOnInit() {}

  logoutFunc() {
    this.authService.logout();
        this.router.navigateByUrl('auth');
  }
}

// jwt.service.ts
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private tokenKey = 'access_token';
  private currentUser='current_user';

  getToken(): string | null {
    return localStorage.getItem(this.tokenKey);
  }

  saveToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
  }

  saveUser(user){
    localStorage.setItem(this.currentUser,JSON.stringify(user))
  }

  getCurrentUser(): Observable<any> {
    const user = JSON.parse(localStorage.getItem(this.currentUser));
    return of(user); // Utilisez of pour créer un observable immédiat avec la valeur de l'utilisateur
  }

  destroyToken(): void {
    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.currentUser);
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    return token !== null && token !== undefined;
  }
}
